import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2, {
  "confirmButtonColor": "#ffad12",
  "cancelButtonColor": "#6b7280",
  "customClass": "sweetalert",
  "showClass": {
    "popup": "animate__animated animate__fadeIn"
  },
  "hideClass": {
    "popup": "animate__animated animate__fadeOut"
  }
});

export default ({}, inject) => {
  inject('swal', Vue.swal)
}
